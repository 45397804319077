import Vue from 'vue'
import VueRouter from 'vue-router'
import Ubpf from '@/components/Ubpf'
import Homepage from '@/components/Homepage'
import PaymentWebhook from '@/components/payments/PaymentWebhook'
import PaymentRedirect from '@/components/payments/PaymentRedirect'
import Carnavalskrant from '@/components/Carnavalskrant'
import Liedjesfestival from '@/components/Liedjesfestival'
import Contact from '@/components/Contact'
import Stempelactie from '@/components/Stempelactie'
import ValidateClubLink from '@/components/payments/ValidateClubLink'
import ClubPayment from '@/components/payments/ClubPayment'
import ClubPaymentRedirect from '@/components/payments/ClubPaymentRedirect'
import Dwaile from '@/components/Dwaile'
import Kindercarnaval from '@/components/Kindercarnaval'
import Jeugdraad from '@/components/Jeugdraad'
import Optocht from '@/components/Optocht'
//import Jury from '@/components/Jury'

import Pagadder from '@/components/Pagadder'
import Webshop from '@/components/webshop/Webshop'
import AboutUs from '@/components/AboutUs'
import Jubileum from '@/components/Jubileum'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Homepage
  },
  {
    path: '/ubpf',
    name: 'Ulster Bierproeffestival',
    component: Ubpf
  },
  {
    path: '/jeugdraad',
    name: 'Jeugdraad',
    component: Jeugdraad
  },
  {
    path: '/carnavalskrant',
    name: 'Carnavalskrant',
    component: Carnavalskrant
  },
  {
    path: '/liedjesfestival',
    name: 'Liedjesfestival',
    component: Liedjesfestival
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/optocht',
    name: 'Optocht',
    component: Optocht
  },
  {
    path: '/stempelactie',
    name: 'Stempelactie',
    component: Stempelactie
  },
  {
    path: '/dwaile',
    name: 'Dwaile',
    component: Dwaile
  },
  {
    path: '/kindercarnaval',
    name: 'Kindercarnaval',
    component: Kindercarnaval
  },
  {
    path: '/pagadder',
    name: 'Pagadder',
    component: Pagadder
  },
  {
    path: '/historie',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/jubileumfeest',
    name: 'Jubileum',
    component: Jubileum
  },
  {
    path: '/webshop',
    name: 'Webshop',
    component: Webshop
  },
  {
    path: '/payments/webhook/:orderID',
    name: 'webhook',
    component: PaymentWebhook
  },
  {
    path: '/payments/redirect/:orderID',
    name: 'redirect',
    component: PaymentRedirect
  },
  {
    path: '/betaling/:momentID/:memberID',
    name: 'ValidatieClubLink',
    component: ValidateClubLink
  },
  {
    path: '/betaling/clubvan111',
    name: 'ClubPayment',
    component: ClubPayment
  },
  {
    path: '/betaling/clubvan111/redirect/:paymentID',
    name: 'clubPaymentRedirect',
    component: ClubPaymentRedirect
  },
  // {
  //   path: '/rapport',
  //   name: 'jury',
  //   component: Jury
  // },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
