<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Jubileumfeest 14-5-2022</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              We kijken inmiddels terug op een prachtige Carnaval. Jullie hebben
              echter nog een feestje van ons tegoed, Zoals jullie weten bestaan
              we 55 jaar en dat moet gevierd worden! <br />
              Zaterdag 14 mei aanstaande hopen we jullie vanaf 21u te
              verwelkomen in 't Spoor011. Op het programma staan De Alpenzusjes,
              Stefan Storm en tot in de vroege uurtjes DJ T-GO en Sandro<br /><br />
              Kaarten zijn
              <b
                ><a href="https://www.ticketkantoor.nl/shop/ahc" target="_blank"
                  >hier</a
                ></b
              >
              verkrijgbaar en kosten €5,- Dit is inclusief een consumptie en
              garderobe. Bestel snel want op=op'
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutModules">
      <v-img width="80%" contain src="@/assets/FlyerJubileum.jpg"></v-img>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style>
</style>