<template>
    <v-container>
        <h1 class="ma-4 d-flex justify-center">
            <span class="ahcRed">Jeugdraod</span>
        </h1>
        <v-layout class="ma-3" name="layoutIntro">
            <v-flex xs12 class="ma-2">
                <v-alert outlined color="#ebb63b">
                    <v-card flat pa-2>
                        <p class="ahcGreen" style="white-space: pre-wrap">
                            {{ jeugdraadIntro }}
                        </p>
                    </v-card>
                </v-alert>
            </v-flex>
        </v-layout>
        <v-layout row wrap class="ma-3" name="layoutModules">
            <v-flex xs12 md7 name="flexModulesNews">
                <v-flex xs12 class="ma-2" name="flexCarrousel" v-if="contentJeugdraad.pics">
                    <v-card v-if="contentJeugdraad.pics.length > 0">
                        <v-system-bar flat color="#ebb63b"> </v-system-bar>
                        <v-tabs v-model="tab" align-with-title color="#cc212f">
                            <v-tabs-slider color="#cc212f"></v-tabs-slider>
                            <v-tab v-for="(pic, i) in contentJeugdraad.pics" :key="i">
                                {{ pic.name }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="(pic, i) in contentJeugdraad.pics" :key="i">
                                <v-card flat>
                                    <v-row class="d-flex justify-space-around ma-3">
                                        <img v-if="pic.image" :src="pic.foto" style="max-width: 95%; object-fit: contain;" />
                                    </v-row>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-flex>
            </v-flex>

            <v-flex xs12 md5 name="flexModulesProgramDownloads">
                <v-flex xs12 class="ma-2" name="flexForm">
                    <v-card>
                        <v-system-bar class="pa-4" color="#ebb63b">
                            <span class="ahcRed subheading">
                                <b>Contact</b>
                            </span>
                        </v-system-bar>
                        <v-form class="pa-2">
                            <v-text-field v-model="contactForm.firstName" label="Je naam" required></v-text-field>
                            <v-text-field v-model="contactForm.lastName" label="Je achternaam" required></v-text-field>
                            <v-text-field v-model="contactForm.telNumber" type="number"
                                label="Je telefoonnummer"></v-text-field>
                            <v-text-field v-model="contactForm.email" label="Je emailadres" required></v-text-field>
                            <v-textarea v-model="contactForm.message" label="Stel je vraag!" required></v-textarea>
                            <v-checkbox v-model="contactForm.agreedCheck" required>
                                <div slot="label">
                                    Ga ja akkoord met de verwerking van je gegevens volgens het
                                    <span @click="conditions = true">
                                        <b>privacybeleid</b>
                                    </span>
                                    van AHC de Vossen?
                                </div>
                            </v-checkbox>
                            <div class="text-center mb-2">
                                <span class="center red--text" v-if="feedback">{{
                                    feedback
                                }}</span>
                                <span class="center green--text" v-if="posFeedback">{{
                                    posFeedback
                                }}</span>
                            </div>
                            <div class="d-flex justify-space-around align-center">
                                <v-btn class="ma-3" @click="processContact()">
                                    <span>Vraag versturen</span>
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card>
                </v-flex>
            </v-flex>
        </v-layout>
        <v-dialog v-model="conditions" width="500">
            <v-card>
                <v-system-bar class="pa-4" color="#ebb63b">
                    <v-card-title class="ahcRed">Privacy beleid</v-card-title>
                </v-system-bar>
                <v-card-text>
                    <p class="ahcGreen" tyle="white-space: pre-wrap;">
                        Stichting AHC de Vossen legt gegevens vast zoals naam, adres,
                        woonplaats, email adressen en overige gegevens die noodzakelijk zijn
                        van onze (oud)leden, vrijwilligers , leden “Club van 111”,
                        deelnemers, gebruikers van onze contactformulieren en adverteerders.
                        <br />
                        <br />Wij verstrekken uw gegevens hierbij niet aan externe partijen.
                        Alle aan ons toevertrouwde informatie wordt door ons vertrouwelijk
                        behandeld. Uw gegevens worden alleen gebruikt voor de doeleinden
                        waarvoor ze zijn verkregen.
                        <br />
                        <br />U kunt zich te allen tijde afmelden als vrijwilliger,
                        donateur, adverteerder of deelnemer. Stuur daarvoor een mail aan
                        info@ahcdevossen.nl. Ook adreswijzigingen en andere wijzigingen kunt
                        u via deze weg doorgeven aan ons secretariaat.
                        <br />
                        <br />Stichting AHC de Vossen zorgt voor een goede en passende
                        beveiliging van de aan haar toevertrouwde persoonsbestanden.
                    </p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#ebb63b" text @click="conditions = false">Terug</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
            panel: 0,
            tab: null,
            contactForm: {},
            feedback: null,
            posFeedback: null,
            conditions: false,
        };
    },
    computed: {
        jeugdraadIntro() {
            return this.$store.state.intros.jeugdraad;
        },
        contentJeugdraad() {
            return this.$store.state.contentJeugdraad;
        },

    },
    methods: {
        validateForm() {
            this.feedback = null;
            if (this.contactForm.firstName) {
                if (this.contactForm.lastName) {
                    if (
                        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                            this.contactForm.email
                        )
                    ) {
                            if (this.contactForm.agreedCheck) {
                                this.feedback = null;
                                return true;
                            } else {
                                this.feedback =
                                    "Je moet akkoord gaan met ons privacy beleid om dit formulier te kunnen versturen";
                                return false;
                            }
                    } else {
                        this.feedback = "Je moet een geldig emailadres invullen";
                        return false;
                    }
                } else {
                    this.feedback = "Je moet een achternaam invullen";
                    return false;
                }
            } else {
                this.feedback = "Je moet een voornaam invullen";
                return false;
            }
        },
        processContact() {
            if (this.validateForm()) {
                this.$store.dispatch("sendJeugdraadForm", { contact: this.contactForm });
                this.contactForm = {};
                this.posFeedback = "Je vraag is in goede staat ontvangen!";
            }
        },
    },
    created() {
        this.$store.dispatch("loadContentJeugdraad");
    },
};
</script>
  
<style></style>