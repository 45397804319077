<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Liedjesfestival</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ liedjesIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutModules">
      <v-flex xs12 md7 name="flexModulesNewsWinners">
        <!-- <v-flex xs12 class="ma-2" name="flexNews">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Nieuws over het liedjesfestival</b>
              </span>
            </v-system-bar>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel
                v-model="panel"
                v-for="(item, i) in newsItems"
                :key="i"
              >
                <v-expansion-panel-header class="ahcGreen">
                  <b>{{ item.title }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-flex class="d-flex justify-center" xs12 v-if="item.foto">
                      <img :src="newsImages[item.id]" />
                    </v-flex>
                    <v-chip
                      v-for="(tag, j) in item.tags"
                      :key="j"
                      class="ma-2"
                      color="#ebb63b"
                      router
                      :to="`/${tag}`"
                    >
                      <span class="white--text">{{ tag }}</span>
                    </v-chip>
                    <v-card-text>
                      <p class="ahcGreen" tyle="white-space: pre-wrap;">
                        {{ item.text }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-flex> -->
        <v-flex xs12 class="ma-2">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Laatste winnaars </b>
              </span>
            </v-system-bar>
            <p class="ahcGreen pa-2" style="white-space: pre-wrap">
              {{ contentLiedjes.text }}
            </p>
            <div class="ma-4 d-flex justify-center">
              <iframe
                width="100%"
                height="500px"
                :src="`https://www.youtube.com/embed/${contentLiedjes.link}`"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex xs12 md5 name="flexModulesEnlistmentDocs">
        <v-flex xs12 class="ma-2" v-if="contentLiedjes.enlistmentOpen">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b
                  >Inschrijven voor editie
                  {{ contentLiedjes.enlistmentYear }}</b
                >
              </span>
            </v-system-bar>
            <v-form class="ma-3">
              <v-text-field
                v-model="newParticipant.contactName"
                label="Naam contactpersoon"
              ></v-text-field>
              <v-text-field
                v-model="newParticipant.contactEmail"
                label="Email adres contactpersoon"
              ></v-text-field>
              <v-text-field
                v-model="newParticipant.contactPhone"
                label="Telefoonnummer contactpersoon"
              ></v-text-field>
              <v-text-field
                v-model="newParticipant.actName"
                label="Naam van de groep/artiest"
              ></v-text-field>
              <v-text-field
                v-model="newParticipant.songName"
                label="Naam van het lied"
                number
              ></v-text-field>
              <v-text-field
                v-model="newParticipant.numberOfParticipants"
                label="Met hoeveel personen is de groep?"
              ></v-text-field>
              <v-file-input
                v-model="newParticipant.form"
                label="Upload je verklaring van originaliteit"
              ></v-file-input>
              <v-checkbox
                v-model="newParticipant.agreed"
                label="Ik ga akkoord met de algemene voorwaarden van het liedjesfestival"
                type="checkbox"
              ></v-checkbox>
              <div class="text-center mb-2">
                <span class="center red--text" v-if="feedback">{{
                  feedback
                }}</span>
                <span class="center green--text" v-if="posFeedback">{{
                  posFeedback
                }}</span>
              </div>
              <v-btn
                class="ma-3"
                v-if="
                  newParticipant.contactName &&
                  newParticipant.contactEmail &&
                  newParticipant.contactPhone &&
                  newParticipant.actName &&
                  newParticipant.songName &&
                  newParticipant.numberOfParticipants &&
                  newParticipant.form &&
                  newParticipant.agreed
                "
                @click="enlistParticipant()"
                >Aanmelding versturen</v-btn
              >
            </v-form>
          </v-card>
        </v-flex>

        <v-flex xs12 class="ma-2" v-if="contentLiedjes.docs">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Documenten</b>
              </span>
            </v-system-bar>
            <v-list v-if="contentLiedjes.docs.length > 0" two-line>
              <v-list-item v-for="(doc, i) in contentLiedjes.docs" :key="i">
                <v-list-item-icon
                  v-if="doc.doc_id"
                  @click="downloadFile(doc.title, doc.doc_id, doc.type)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Axios from "axios";
import firebase from "firebase";
export default {
  data() {
    return {
      panel: 0,
      newParticipant: {},
      feedback: null,
      posFeedback: null,
    };
  },
  computed: {
    newsItems() {
      return this.$store.state.liedjesNewsItems;
    },
    liedjesIntro() {
      return this.$store.state.intros.liedjes;
    },
    newsImages() {
      return this.$store.state.newsImages;
    },
    contentLiedjes() {
      return this.$store.state.contentLiedjes;
    },
  },
  methods: {
    enlistParticipant() {
      if (this.validateForm()) {
        this.newParticipant.id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        this.newParticipant.creationDate = dd + "/" + mm + "/" + yyyy;
        this.newParticipant.checked = false;
        this.newParticipant.docType = this.newParticipant.form.name
          .split(".")
          .pop();
        this.newParticipant.year = this.contentLiedjes.enlistmentYear;
        let storageRef = firebase.storage().ref();
        storageRef
          .child(`participantDocs/liedjes/${this.newParticipant.id}`)
          .put(this.newParticipant.form);
        delete this.newParticipant.form;
        this.$store.dispatch("enlistParticipant", {
          participant: this.newParticipant,
        });
        this.newParticipant = {};
        this.posFeedback =
          "Uw inschrijving is in goede staat ontvangen en u ontvangt hiervan bevestiging via mail.";
      }
    },
    validateForm() {
      this.feedback = null;
      this.posFeedback = null;
      if (
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
          this.newParticipant.contactEmail
        )
      ) {
        return true;
      } else {
        this.feedback = this.feedback = "U moet een geldig emailadres invullen";
        return false;
      }
    },
    downloadFile(title, id, file_type) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;

      let file = firebase.storage().ref(`publicDocs/liedjes/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
  },
  created() {
    this.$store.dispatch("loadContentLiedjes");
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
</style>