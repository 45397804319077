<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Een stukje historie...</span>
    </h1>
    <v-layout row wrap class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ aboutUsIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
      <v-flex xs12 class="ma-2" name="flexPrinsTabs">
        <v-card>
          <v-system-bar flat color="#ebb63b"> </v-system-bar>
          <v-tabs v-model="tab" align-with-title color="#cc212f">
            <v-tabs-slider color="#cc212f"></v-tabs-slider>
            <v-tab v-for="(prins, i) in contentAboutUs.carrousel" :key="i">
              {{ prins.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(prins, i) in contentAboutUs.carrousel" :key="i">
              <v-card flat>
                <v-row class="d-flex justify-space-around ma-3">
                  <img
                    v-if="prins.image"
                    :src="prins.foto"
                    max-width="80%"
                    class="img-circle"
                  />
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <p class="ahcGreen" style="white-space: pre-wrap">
                    {{ prins.story }}
                  </p>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    aboutUsIntro() {
      return this.$store.state.intros.aboutUs;
    },
    contentAboutUs() {
      return this.$store.state.contentAboutUs;
    },
  },
  beforeCreate() {
    this.$store.dispatch("loadContentAboutUs");
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
</style>