<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Optocht</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ summerIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutModules">
      <v-flex xs12 md5 name="flexModulesEnlistmentDocs">
         <v-flex xs12 class="ma-2">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Inschrijven voor de optocht 2024</b>
              </span>
            </v-system-bar>
            <v-form class="ma-3">
              <v-text-field
                v-model="newParticipant.contactName"
                label="Naam contactpersoon"
              ></v-text-field>
              <v-text-field
                v-model="newParticipant.contactEmail"
                label="Email adres contactpersoon"
              ></v-text-field>
              <v-text-field
                v-model="newParticipant.contactPhone"
                label="Telefoonnummer contactpersoon"
              ></v-text-field>
              <v-text-field
                v-model="newParticipant.actName"
                label="Naam van de groep/artiest"
              ></v-text-field>
              <v-radio-group v-model="newParticipant.groupType">
                <template v-slot:label>
                  <div>Voor welke categorie wil je je inschrijven?</div>
                </template>
                <v-radio
                  label="Individuelen (1-4 personen) "
                  value="Individuelen"
                ></v-radio>
                <v-radio label="Groep (5+ personen) " value="Groep"></v-radio>
                <v-radio label="Wagenbouwers " value="Wagen"></v-radio>
              </v-radio-group>
              <v-checkbox
                v-model="newParticipant.agreed"
                label="Ik ga akkoord met de algemene reglementen van de optocht"
                type="checkbox"
              ></v-checkbox>
              <div class="text-center mb-2">
                <span class="center red--text" v-if="feedback">{{
                  feedback
                }}</span>
                <span class="center green--text" v-if="posFeedback">{{
                  posFeedback
                }}</span>
              </div>
              <v-btn
                class="ma-3"
                v-if="
                  newParticipant.contactName &&
                  newParticipant.contactEmail &&
                  newParticipant.contactPhone &&
                  newParticipant.actName &&
                  newParticipant.groupType &&
                  newParticipant.agreed
                "
                @click="enlistParticipant()"
                >Aanmelding versturen</v-btn
              >
            </v-form>
          </v-card>
        </v-flex> 

        <v-flex xs12 class="ma-2" v-if="contentSummer.docs">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Documenten</b>
              </span>
            </v-system-bar>
            <v-list v-if="contentSummer.docs.length > 0" two-line>
              <v-list-item v-for="(doc, i) in contentSummer.docs" :key="i">
                <v-list-item-icon
                  v-if="doc.doc_id"
                  @click="downloadFile(doc.title, doc.doc_id, doc.type)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Axios from "axios";
import firebase from "firebase";
export default {
  data() {
    return {
      panel: 0,
      newParticipant: {},
      feedback: null,
      posFeedback: null,
    };
  },
  computed: {
    newsItems() {
      return this.$store.state.summerNewsItems;
    },
    summerIntro() {
      return this.$store.state.intros.summer;
    },
    newsImages() {
      return this.$store.state.newsImages;
    },
    contentSummer() {
      return this.$store.state.contentSummer;
    },
  },
  methods: {
    enlistParticipant() {
      if (this.validateForm()) {
        this.newParticipant.id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        this.newParticipant.creationDate = dd + "/" + mm + "/" + yyyy;
        this.newParticipant.checked = false;
        this.$store.dispatch("enlistSummerParticipant", {
          participant: this.newParticipant,
        });
        this.newParticipant = {};
        this.posFeedback =
          "Uw inschrijving is in goede staat ontvangen en u ontvangt hiervan bevestiging via mail.";
      }
    },
    validateForm() {
      this.feedback = null;
      this.posFeedback = null;
      if (
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
          this.newParticipant.contactEmail
        )
      ) {
        return true;
      } else {
        this.feedback = this.feedback = "U moet een geldig emailadres invullen";
        return false;
      }
    },
    downloadFile(title, id, file_type) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;

      let file = firebase.storage().ref(`publicDocs/summer/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
  },
  created() {
    this.$store.dispatch("loadContentSummer");
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
</style>