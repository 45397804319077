<template>
  <v-container>
    <v-card class="ma-5" elevation="0" v-if="!order">
      <v-row class="d-flex justify-space-around mb-6">
        <v-progress-circular class="ma-5" :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
      </v-row>
      <v-row class="d-flex justify-space-around mb-6">
        <h3>Ophalen order...</h3>
      </v-row>
    </v-card>
    <v-card class="ma-5" elevation="0" v-if="order">
      <v-card-title>{{cardTitle}}</v-card-title>
      <v-card-text>{{cardText}}</v-card-text>
      <v-card-actions class="d-flex justify-space-around mb-6">
        <v-btn>
          <router-link :to="{name: 'Home'}" class="grey--text">Terug naar de homepage!</router-link>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "../../firebase/init";
export default {
  data() {
    return {
      order: null,
      cardTitle: null,
      cardText: null
    };
  },
  methods: {
    fillMessage(e) {
      if (e) {
        this.cardTitle = "Er is iets misgegaan!";
        this.cardText = "Neem contact op met info@ahcdevossen.nl";
      } else {
        if (this.order.mollieStatus == "paid") {
          this.cardTitle = "Bedankt voor uw bestelling!";
          this.cardText =
            "Uw betaling is goed verlopen en we gaan u bestelling verder verwerken.\n U ontvangt op korte termijn een email met een bevestiging.";
        } else if (
          this.order.mollieStatus == "expired" ||
          this.order.mollieStatus == "failed"
        ) {
          this.cardTitle = "De betaling is niet gelukt!";
          this.cardText =
            "U heeft de betaling afgebroken of de betaling is wegens technische redenen niet gelukt.\n Vul een nieuw bestelformulier in als u toch een order wilt plaatsen.\n\n Mocht u wel betaald hebben en u krijgt deze boodschap is er iets misgegaan in de verwerking van de order. Neem in dat geval contact op met info@ahcdevossen.nl";
        } else if (this.order.mollieStatus == "canceled") {
          this.cardTitle = "U heeft de betaling afgebroken";
          this.cardText =
            "Mocht u toch een order willen plaatsen kunt u een nieuw bestelformulier invullen.\n Indien de betaling om andere redenen niet is gelukt kunt u contact opnemen met info@ahcdevossen.nl";
        }
      }
    }
  },
  created() {
    let lookupOrder = firebase.functions().httpsCallable("lookupOrder");
    setTimeout(
      lookupOrder({ id: this.$route.params.orderID })
        .then(result => {
          this.order = result.data.order;
          this.fillMessage();
        })
        .catch(error => {
          this.fillMessage(error);
        }),
      5000
    );
  }
};
</script>

<style>
</style>