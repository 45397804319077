<template>
  <v-container>
    <v-card class="ma-5" elevation="0" v-if="!payment">
      <v-row class="d-flex justify-space-around mb-6">
        <v-progress-circular
          class="ma-5"
          :size="70"
          :width="7"
          color="#cc212f"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-row class="d-flex justify-space-around mb-6">
        <h3>Ophalen betaling...</h3>
      </v-row>
    </v-card>
    <v-card class="ma-5" elevation="0" v-if="payment">
      <v-card-title>{{ cardTitle }}</v-card-title>
      <v-card-text>{{ cardText }}</v-card-text>
      <v-card-actions class="d-flex justify-space-around mb-6">
        <v-btn>
          <router-link :to="{ name: 'Home' }" class="grey--text"
            >Terug naar de homepage!</router-link
          >
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "../../firebase/init";
export default {
  data() {
    return {
      payment: null,
      cardTitle: null,
      cardText: null,
    };
  },
  methods: {
    fillMessage(e) {
      if (e) {
        this.cardTitle = "Er is iets misgegaan!";
        this.cardText = "Neem contact op met info@ahcdevossen.nl";
      } else {
        if (this.payment.mollieStatus == "paid") {
          this.cardTitle = "Bedankt voor uw betaling!";
          this.cardText =
            "Uw betaling is goed verlopen en we gaan hem verwerken.\n U ontvangt op korte termijn een email met een bevestiging.";
        } else if (
          this.payment.mollieStatus == "expired" ||
          this.payment.mollieStatus == "failed"
        ) {
          this.cardTitle = "De betaling is niet gelukt!";
          this.cardText =
            "U heeft de betaling afgebroken of de betaling is wegens technische redenen niet gelukt.\n U kunt de betaling opnieuw proberen uitvoeren.\n\n Mocht u wel betaald hebben en u krijgt deze boodschap is er iets misgegaan in de verwerking van de betaling. Neem in dat geval contact op met club111@ahcdevossen.nl";
        } else if (this.payment.mollieStatus == "canceled") {
          this.cardTitle = "U heeft de betaling afgebroken";
          this.cardText =
            "Mocht u toch uw contributie willen betalen kunt u de procedure opnieuw doorlopen.\n Indien de betaling om andere redenen niet is gelukt kunt u contact opnemen met info@ahcdevossen.nl";
        }
      }
    },
  },
  created() {
    let lookupClubPayment = firebase
      .functions()
      .httpsCallable("lookupClubPayment");
    setTimeout(
      lookupClubPayment({ id: this.$route.params.paymentID })
        .then((result) => {
          this.payment = result.data.payment;
          this.fillMessage();
        })
        .catch((error) => {
          this.fillMessage(error);
        }),
      5000
    );
  },
};
</script>

<style>
</style>