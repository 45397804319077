<template>
  <span>checking payment</span>
</template>

<script>
import firebase from "../../firebase/init";
export default {
  created() {
    let lookupPayment = firebase.functions().httpsCallable("lookupPayment");
    lookupPayment({ id: this.$route.params.orderID });
  }
};
</script>

<style>
</style>