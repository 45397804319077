<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Stempelactie</span>
    </h1>
     <v-layout class="ma-3" name="layoutPoster">      
       <v-flex xs12 class="ma-2 d-flex justify-center">
          <v-img
        src="@/assets/stempelactie.jpg"
        contain
        max-width="800"
      ></v-img>
      </v-flex></v-layout>
    <v-layout class="ma-3" name="layoutIntro">

      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              Onder de noemer "'k zain blai ak oe zie!" is AHC de Vossen in
              september 2020 gestart met een stempelactie. Dit in samenwerking
              met de locale horeca en de partners Drankenhandel Pluym,
              Drankenhandel Wauters, Bierbrouwerij Vermeersen, Drukkerij Druwa
              en InUlst.<br />
              Met deze actie kunt u bij de deelnemende zaken onder het genot van
              een consumptie of diner een stempel verdienen. Bij inlevering van
              een volle spaarkaart verdient u een unieke badge als dank voor uw
              bijdrage aan de lokale ondernemers. Op deze manier willen we de
              horeca in onze Vossenstad in het zonnetje zettern en er voor
              zorgen dat we er ook na deze vreemde tijden nog terecht kunnen.
              <br />
              Oorspronkelijk was de bedoeling om deze actie tijdens de herfst te
              laten lopen maar de verplichtte sluiting van de horeca gooide roet
              in het eten. Nu de terrassen weer open zijn hebben we besloten om
              de actie tot eind mei door te laten lopen.
              <br><br>
              Wanneer u wilt meedoen met de actie kunt u uw gratis stempelkaart afhalen, en na afloop weer inleveren, bij de volgende vier locaties:<br> 
              <ul>
                  <li>Bistrot En Passent</li>
                  <li>Restaurant de Vest</li>
                  <li>Restaurant pizzeria Méditerranée</li>
                  <li>VVV Hulst</li>
              </ul>
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
 
    <v-layout row wrap class="ma-3" name="layoutModulesUbpf">
      <v-flex xs12 md6 name="flexNewsForm">
        <v-flex xs12 class="ma-2" name="flexNews">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Nieuws</b>
              </span>
            </v-system-bar>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel
                v-model="panel"
                v-for="(item, i) in newsItems"
                :key="i"
              >
                <v-expansion-panel-header class="ahcGreen">
                  <b>{{ item.title }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-flex class="d-flex justify-center" xs12 v-if="item.foto">
                      <img :src="newsImages[item.id]" />
                    </v-flex>
                    <v-chip
                      v-for="(tag, j) in item.tags"
                      :key="j"
                      class="ma-2"
                      color="#ebb63b"
                      router
                      :to="`/${tag}`"
                    >
                      <span class="white--text">{{ tag }}</span>
                    </v-chip>
                    <v-card-text>
                      <p class="ahcGreen" tyle="white-space: pre-wrap;">
                        {{ item.text }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex xs12 md6 name="flexRulesForm">
          <v-flex xs12 class="ma-2" name="flexRules">
               <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Spelregels</b>
              </span>
            </v-system-bar>
            <v-card-text>
                <p class="ahcGreen" style="white-space: pre-wrap">
              <ul>
                  <li>De stempelkaart is gratis</li>
                  <li>Eerste stempel is gratis (voorgedrukt)</li>
                  <li>1 consumptie = 1 stempel</li>
                  <li>Maximaal 1 stempel per zaak</li>
                  <li>Maximaal 1 stempelkaart per persoon</li>
                  <li>Er is een maximaal aantal kaarten beschikbaar, op = op</li>
                  <li>Actie loopt van 19-9-2020 t/m 31/05/2021</li>
                  <li>Stempelkaarten kunnen ten laatste 6/6/2021 worden ingeleverd</li>
                  <li>Een volle stempelkaart met elf verschillende stempels is goed voor een unieke badge met het logo van de actie. De badges hebben een doorsnede van 7cm</li>
                  <li>De badges worden enkele weken na de actie periode verspreid</li>
                  <li>Binnen de horeca zaken moeten de richtlijnen van het RIVM en de aanwijzingen van het personeel opgevolgt worden. Dit kan betekenen dat u in geval van drukte op een later moment terug moet komen.</li>
                  <li>Bij twijfel over de reglementen beslist het bestuur van AHC de Vossen</li>
              </ul>
            </p>
                </v-card-text></v-card>
          </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      panel: 0,
    };
  },
  computed: {
    newsItems() {
      return this.$store.state.stempelactieNewsItems;
    },

    newsImages() {
      return this.$store.state.newsImages;
    },
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
</style>