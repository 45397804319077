<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">De Dwaile van Ulst</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ dwaileIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  computed: {
    dwaileIntro() {
      return this.$store.state.intros.dwaile;
    },
  },
};
</script>

<style>
</style>