<template>
  <v-app>
    <Navbar />
    <v-main class="ma-4">
      <router-view></router-view>
    </v-main>
    <Bottom />
  </v-app>
</template>

<script>
import Navbar from "./views/Navbar";
import Bottom from "./views/Bottom";
export default {
  name: "App",
  components: {
    Navbar,
    Bottom
  }
};
</script>
