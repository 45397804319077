<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Contact informatie</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ contactIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutModulesContact">
      <v-flex xs12 class="ma-2" name="flexForm">
        <v-card>
          <v-system-bar class="pa-4" color="#ebb63b">
            <span class="ahcRed subheading">
              <b>Contact</b>
            </span>
          </v-system-bar>
          <v-form class="pa-2">
            <v-text-field
              v-model="contactForm.firstName"
              label="Uw voornaam"
              required
            ></v-text-field>
            <v-text-field
              v-model="contactForm.lastName"
              label="Uw achternaam"
              required
            ></v-text-field>
            <v-text-field
              v-model="contactForm.telNumber"
              type="number"
              label="Uw telefoonnummer"
            ></v-text-field>
            <v-text-field
              v-model="contactForm.email"
              label="Uw emailadres"
              required
            ></v-text-field>
            <v-select
              v-model="contactForm.subject"
              :items="contactOptions"
              label="Waarover wil u contact opnemen?"
              required
            ></v-select>
            <v-textarea
              v-model="contactForm.message"
              label="Wat is uw boodschap?"
              required
            ></v-textarea>
            <v-checkbox v-model="contactForm.agreedCheck" required>
              <div slot="label">
                Gaat u akkoord met de verwerking van uw gegevens volgens het
                <span @click="conditions = true">
                  <b>privacybeleid</b>
                </span>
                van AHC de Vossen?
              </div>
            </v-checkbox>
            <div class="text-center mb-2">
              <span class="center red--text" v-if="feedback">{{
                feedback
              }}</span>
              <span class="center green--text" v-if="posFeedback">{{
                posFeedback
              }}</span>
            </div>
            <div class="d-flex justify-space-around align-center">
              <v-btn class="ma-3" @click="processContact()">
                <span>Formulier verzenden</span>
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="conditions" width="500">
      <v-card>
        <v-system-bar class="pa-4" color="#ebb63b">
          <v-card-title class="ahcRed">Privacy beleid</v-card-title>
        </v-system-bar>
        <v-card-text>
          <p class="ahcGreen" tyle="white-space: pre-wrap;">
            Stichting AHC de Vossen legt gegevens vast zoals naam, adres,
            woonplaats, email adressen en overige gegevens die noodzakelijk zijn
            van onze (oud)leden, vrijwilligers , leden “Club van 111”,
            deelnemers, gebruikers van onze contactformulieren en adverteerders.
            <br />
            <br />Wij verstrekken uw gegevens hierbij niet aan externe partijen.
            Alle aan ons toevertrouwde informatie wordt door ons vertrouwelijk
            behandeld. Uw gegevens worden alleen gebruikt voor de doeleinden
            waarvoor ze zijn verkregen.
            <br />
            <br />U kunt zich te allen tijde afmelden als vrijwilliger,
            donateur, adverteerder of deelnemer. Stuur daarvoor een mail aan
            info@ahcdevossen.nl. Ook adreswijzigingen en andere wijzigingen kunt
            u via deze weg doorgeven aan ons secretariaat.
            <br />
            <br />Stichting AHC de Vossen zorgt voor een goede en passende
            beveiliging van de aan haar toevertrouwde persoonsbestanden.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ebb63b" text @click="conditions = false">Terug</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      contactForm: {},
      contactOptions: [
        "Algemeen",
        "Ulster bierproeffestival",
        "Liedjesfestival",
        "Krant",
        "Club van 111",
        "Webshop",
        "Optocht",
      ],
      feedback: null,
      posFeedback: null,
      conditions: false,
    };
  },
  computed: {
    contactIntro() {
      return this.$store.state.intros.contact;
    },
  },
  methods: {
    validateForm() {
      this.feedback = null;
      if (this.contactForm.firstName) {
        if (this.contactForm.lastName) {
          if (
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
              this.contactForm.email
            )
          ) {
            if (this.contactForm.subject) {
              if (this.contactForm.agreedCheck) {
                this.feedback = null;
                return true;
              } else {
                this.feedback =
                  "U moet akkoord gaan met ons privacy beleid om dit formulier te kunnen versturen";
                return false;
              }
            } else {
              this.feedback = "U moet onderwerp kiezen";
              return false;
            }
          } else {
            this.feedback = "U moet een geldig emailadres invullen";
            return false;
          }
        } else {
          this.feedback = "U moet een achternaam invullen";
          return false;
        }
      } else {
        this.feedback = "U moet een voornaam invullen";
        return false;
      }
    },
    processContact() {
      if (this.validateForm()) {
        this.$store.dispatch("sendContactForm", { contact: this.contactForm });
        this.contactForm = {};
        this.posFeedback = "Uw vraag is in goede staat ontvangen!";
      }
    },
  },
};
</script>

<style>
</style>