import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import firebase from '../firebase/init'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newsItems: [],
    ubpfNewsItems: [],
    liedjesNewsItems: [],
    summerNewsItems: [],
    kidsNewsItems: [],
    paperNewsItems: [],
    stempelactieNewsItems: [],
    pagadderNewsItems: [],
    intros: {},
    newsImages: [],
    contentLiedjes: {},
    contentSummer: {},
    contentKids: {},
    contentPaper: {},
    clubPayment: null,
    products: [],
    productImages: [],
    contentAboutUs: {},
    contentHome: {},
    breweries: [],
    contentJeugdraad: {}
  },
  mutations: {
    getNewsItems: (state) => {
      state.newsItems = []
      let loadNewsItems = firebase.functions().httpsCallable("loadNewsItems");
      loadNewsItems().then(result => {
        state.newsItems = result.data.newsItems
        state.newsItems.sort((a, b) => {
          return b.id - a.id
        });

        for (let i = 0; i < state.newsItems.length; i++) {
          if (state.newsItems[i].tags) {
            if (state.newsItems[i].tags.includes("ubpf")) {
              state.ubpfNewsItems.push(state.newsItems[i])
            }
            if (state.newsItems[i].tags.includes("liedjesfestival")) {
              state.liedjesNewsItems.push(state.newsItems[i])
            }
            if (state.newsItems[i].tags.includes("carnavalskrant")) {
              state.paperNewsItems.push(state.newsItems[i])
            }
            if (state.newsItems[i].tags.includes("stempelactie")) {
              state.stempelactieNewsItems.push(state.newsItems[i])
            }
            if (state.newsItems[i].tags.includes("kindercarnaval")) {
              state.kidsNewsItems.push(state.newsItems[i])
            }
            if (state.newsItems[i].tags.includes("pagadder")) {
              state.pagadderNewsItems.push(state.newsItems[i])
            }
            if (state.newsItems[i].tags.includes("stempelactie")) {
              state.stempelactieNewsItems.push(state.newsItems[i])
            }
            if (state.newsItems[i].tags.includes("zomercarnaval")) {
              state.summerNewsItems.push(state.newsItems[i])
            }
          }
        }
      }).catch((e) => {
        console.log('Error in loadNewsItems: ' + e.message)
      })
    },
    loadIntros: (state) => {
      state.intros = {}
      let loadIntros = firebase.functions().httpsCallable("loadIntros");
      loadIntros().then(result => {
        state.intros = result.data.intros
        if (state.intros.themeImage) {
          let storageRef = firebase.storage().ref(`images/theme/${state.intros.themeImage.id}`);
          storageRef.getDownloadURL().then((url) => {
            state.intros.themeImage.url = url
          })
        }
      }).catch((e) => {
        console.log('Error in loadIntros: ' + e.message)
      })
    },
    loadContentLiedjes: (state) => {
      state.contentLiedjes = {}
      let loadContentLiedjes = firebase.functions().httpsCallable("loadContentLiedjes");
      loadContentLiedjes().then(result => {
        state.contentLiedjes = result.data.contentLiedjes
      }).catch((e) => {
        console.log('Error in contentLiedjes: ' + e.message)
      })
    },
    loadContentSummer: (state) => {
      state.contentSummer = {}
      let loadContentSummer = firebase.functions().httpsCallable("loadContentSummer");
      loadContentSummer().then(result => {
        state.contentSummer = result.data.contentSummer
      }).catch((e) => {
        console.log('Error in contentSummer: ' + e.message)
      })
    },
    loadContentKids: (state) => {
      state.contentKids = {}
      let loadContentKids = firebase.functions().httpsCallable("loadContentKids");
      loadContentKids().then(result => {
        state.contentKids = result.data.contentKids
      }).catch((e) => {
        console.log('Error in contentKids: ' + e.message)
      })
    },
    loadContentJeugdraad: (state) => {
      state.contentJeugdraad = {}
      let loadContentJeugdraad = firebase.functions().httpsCallable("loadContentJeugdraad");
      loadContentJeugdraad().then(result => {
        state.contentJeugdraad = result.data.contentJeugdraad
      }).then(async () => {
        for (let item of state.contentJeugdraad.pics) {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/jeugdraad/carrousel/${item.id}`);
            await storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        }
      }).catch((e) => {
        console.log('Error in contentJeugdraad: ' + e.message)
      })
    },
    loadContentPaper: (state) => {
      state.contentPaper = {}
      let loadContentPaper = firebase.functions().httpsCallable("loadContentPaper");
      loadContentPaper().then(result => {
        state.contentPaper = result.data.contentPaper
      }).catch((e) => {
        console.log('Error in contentPaper: ' + e.message)
      })
    },
    loadContentAboutUs: (state) => {
      state.contentAboutUs = {}
      let loadContentAboutUs = firebase.functions().httpsCallable("loadContentAboutUs");
      loadContentAboutUs().then(result => {
        state.contentAboutUs = result.data.contentAboutUs
      }).then(async () => {
        for (let item of state.contentAboutUs.carrousel) {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/aboutUs/carrousel/${item.id}`);
            await storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        }
      }).catch((e) => {
        console.log('Error in contentAboutUs: ' + e.message)
      })
    },
    loadContentHome: (state) => {
      state.contentHome = {}
      let loadContentHome = firebase.functions().httpsCallable("loadContentHome");
      loadContentHome().then(result => {
        state.contentHome = result.data.contentHome
      }).then(() => {
        state.contentHome.prog.forEach(item => {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/home/carrouselProg/${item.id}`);
            storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        })
      }).catch((e) => {
        console.log('Error in contentHome: ' + e.message)
      })
    },
    loadProducts: (state) => {
      state.products = []
      let loadProducts = firebase.functions().httpsCallable("loadProducts")
      loadProducts().then(result => {
        state.products = result.data.products

      }).catch(e => {
        console.log('error in loading products: ' + e.message)
      })
    },
    getProductImages: (state) => {
      state.productImages = []
      let storageRef = firebase.storage().ref("images/products/");
      storageRef.listAll().then((result) => {
        result.items.forEach((imageRef) => {
          imageRef.getDownloadURL().then((url) => {
            let id = JSON.stringify(imageRef).slice(905, 918)
            state.productImages[id] = url
          })
        });
      }).catch(function (error) {
        console.log(error)
      });

    },
    sendContactForm: (state, payload) => {
      try {
        let sendContactForm = firebase.functions().httpsCallable("sendContactForm");
        sendContactForm(payload)
      } catch (error) {
        console.log('Error in sendContactForm: ' + error.message)
      }
    },
    sendJeugdraadForm: (state, payload) => {
      try {
        let sendJeugdraadForm = firebase.functions().httpsCallable("sendJeugdraadForm");
        sendJeugdraadForm(payload)
      } catch (error) {
        console.log('Error in sendContactForm: ' + error.message)
      }
    },
    enlistParticipant: (state, payload) => {
      try {
        let enlistParticipant = firebase.functions().httpsCallable("enlistParticipant");
        enlistParticipant(payload)
      } catch (error) {
        console.log('Error in enlistParticipant: ' + error.message)
      }
    },
    enlistSummerParticipant: (state, payload) => {
      try {
        let enlistSummerParticipant = firebase.functions().httpsCallable("enlistSummerParticipant");
        enlistSummerParticipant(payload)
      } catch (error) {
        console.log('Error in enlistSummerParticipant: ' + error.message)
      }
    },
    pagadderSuggestion: (state, payload) => {
      try {
        let pagadderSuggestion = firebase.functions().httpsCallable("pagadderSuggestion");
        pagadderSuggestion(payload)
      } catch (error) {
        console.log('Error in pagadderSuggestion: ' + error.message)
      }
    },
    getNewsImages: (state) => {
      state.newsImages = []
      let storageRef = firebase.storage().ref("images/news/");
      storageRef.listAll().then((result) => {
        result.items.forEach((imageRef) => {
          imageRef.getDownloadURL().then((url) => {
            let decodeUrl = decodeURIComponent(url)
            let parts = decodeUrl.split('/')
            let id = parts[parts.length - 1].split('?')[0];
            state.newsImages[id] = url
          })
        });
      }).catch(function (error) {
        console.log(error)
      });

    },
    checkPaymentVerification: (state, payload) => {
      try {
        let checkPaymentVerification = firebase.functions().httpsCallable("checkPaymentVerification")
        checkPaymentVerification(payload).then(result => {
          if (result.data.succes) {
            state.clubPayment = result.data.clubPayment
            router.push({ name: "ClubPayment" })
          } else {
            alert(result.data.errorMessage)
          }
        })
      } catch (error) {
        alert(error.message)
      }

    },
    createClubPayment: (state, payload) => {
      let createClubPayment = firebase.functions().httpsCallable("createClubPayment")
      createClubPayment(payload).then(result => {
        if (result.data.succes) {
          window.location.replace(result.data.link)
          console.log(result.data.link)
        } else {
          alert(result.data.errorMessage)
          router.push({ name: "Home" })
        }
      })
    },
    getBreweries: (state) => {
      state.breweries = []
      let loadBreweries = firebase.functions().httpsCallable("loadBreweries");
      loadBreweries().then(result => {
        state.breweries = result.data.breweries
      }).catch((e) => {
        console.log('Error in loadBreweries: ' + e.message)
      })
    },
    ubpfInschrijving: (state, payload) => {
      try {
        let ubpfInschrijving = firebase.functions().httpsCallable("ubpfInschrijving");
        ubpfInschrijving(payload)
      } catch (error) {
        console.log('Error in ubpfInschrijving: ' + error.message)
      }
    }
  },
  actions: {
    getNewsItems: (context) => {
      context.commit('getNewsItems')
    },
    loadIntros: (context) => {
      context.commit('loadIntros')
    },
    loadContentLiedjes: (context) => {
      context.commit('loadContentLiedjes')
    },
    loadContentSummer: (context) => {
      context.commit('loadContentSummer')
    },
    loadContentKids: (context) => {
      context.commit('loadContentKids')
    },
    loadContentJeugdraad: (context) => {
      context.commit('loadContentJeugdraad')
    },
    loadContentPaper: (context) => {
      context.commit('loadContentPaper')
    },
    sendContactForm: (context, payload) => {
      context.commit('sendContactForm', payload)
    },
    sendJeugdraadForm: (context, payload) => {
      context.commit('sendJeugdraadForm', payload)
    },
    getNewsImages: (context) => {
      context.commit('getNewsImages')
    },
    getProductImages: (context) => {
      context.commit('getProductImages')
    },
    enlistParticipant: (context, payload) => {
      context.commit('enlistParticipant', payload)
    },
    enlistSummerParticipant: (context, payload) => {
      context.commit('enlistSummerParticipant', payload)
    },
    checkPaymentVerification: (context, payload) => {
      context.commit('checkPaymentVerification', payload)
    },
    createClubPayment: (context, payload) => {
      context.commit('createClubPayment', payload)
    },
    loadProducts: (context) => {
      context.commit('loadProducts')
    },
    pagadderSuggestion: (context, payload) => {
      context.commit('pagadderSuggestion', payload)
    },
    loadContentAboutUs: (context) => {
      context.commit('loadContentAboutUs')
    },
    loadContentHome: (context) => {
      context.commit('loadContentHome')
    },
    getBreweries: (context) => {
      context.commit('getBreweries')
    },
    ubpfInschrijving: (context, payload) => {
      context.commit('ubpfInschrijving', payload)
    }
  },
  modules: {
  }
})
