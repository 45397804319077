<template>
  <v-container>
    <v-flex class="d-flex justify-center">
      <v-card width="500">
        <v-system-bar class="pa-4" color="#ebb63b">
          <span class="ahcRed subheading">
            <b>Beveiligingscode</b>
          </span>
        </v-system-bar>
        <v-card-text>
          <span
            >Voer de viercijferige beveiligingscode in die onder de link op de
            brief vermeld staat.</span
          >
          <v-row class="d-flex justify-center">
            <v-text-field
              class="shrink"
              width="100"
              placeholder="1234"
              maxlength="4"
              v-model="verificationCode"
              :rules="[verificationRules]"
            >
            </v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              v-if="verificationCode.length == 4"
              color="#ebb63b"
              @click="checkVerification"
              >Verzenden</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-overlay :value="buzzy" opacity="0.5">
      <span>U gegevens worden opgehaald...</span>
      <br />
      <v-progress-circular
        indeterminate
        :size="70"
        :width="7"
        color="#cc212f"
        class="mx-auto"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      verificationCode: "",
      verificationRules: (v) =>
        /^\d+$/.test(v) || "De code mag enkel uit cijfers bestaan",
      buzzy: false,
    };
  },
  methods: {
    checkVerification() {
      this.buzzy = true;
      this.$store.dispatch("checkPaymentVerification", {
        momentID: this.$route.params.momentID,
        memberID: this.$route.params.memberID,
        check: parseInt(this.verificationCode),
      });
      setTimeout(() => {
        this.buzzy = false;
      }, 5000);
    },
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
</style>