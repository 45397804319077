<template>
  <nav>
    <v-app-bar
      shrink-on-scroll
      app
      fade-img-on-scroll
      src="@/assets/logo_ahc.png"
      scrol-target="#scrolling-techniques-3"
      color="#ebb63b"
    >
      <v-app-bar-nav-icon
        color="#cc213b"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <template v-slot:img="{ props }">
        <v-img v-bind="props" contain height="120px" class="ma-2"></v-img>
      </template>

      <v-spacer></v-spacer>
      <v-toolbar-title>
        <span class="font-weight-black ahcGreen">Stichting AHC de Vossen</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <a href="https://www.facebook.com/ahc.devossen" target="_blank">
        <v-icon class="ma-1" color="#cc213b">mdi-facebook</v-icon>
      </a>
      <a href="https://www.instagram.com/ahc.de.vossen/" target="_blank">
        <v-icon class="ma-1" color="#cc213b">mdi-instagram</v-icon>
      </a>
      <template v-slot:extension>
        <v-tabs align-with-title center-active background-color="#305645">
          <v-spacer></v-spacer>
          <v-tabs-slider color="#305645"></v-tabs-slider>
          <v-tab v-for="link in links" :key="link.text" router :to="link.route">
            <v-icon color="#cc213b">{{ link.icon }}</v-icon>
          </v-tab>
          <v-spacer></v-spacer>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" color="#ebb63b">
      <v-list>
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon color="#305645">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="ahcGreen">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
      links: [
        {
          icon: "mdi-home-circle",
          text: "Home",
          route: "/",
        },
        {
          icon: "mdi-party-popper",
          text: "Optocht",
          route: "/optocht",
        },
        {
          icon: "mdi-account-group",
          text: "Jeugdraad",
          route: "/jeugdraad",
        },
       
       
        // {
        //   icon: "mdi-party-popper",
        //   text: "Jubileumfeest",
        //   route: "/jubileumfeest",
        // },
        // {
        //   icon: "mdi-cart",
        //   text: "Webshop",
        //   route: "/webshop",
        // },
        {
          icon: "mdi-history",
          text: "Historie",
          route: "/historie",
        },
        {
          icon: "mdi-balloon",
          text: "Kindercarnaval",
          route: "/kindercarnaval",
        },
        {
          icon: "mdi-newspaper-variant",
          text: "Carnavalskrant",
          route: "/carnavalskrant",
        },

        {
          icon: "mdi-music",
          text: "Liedjesfestival",
          route: "/liedjesfestival",
        },
        {
          icon: "mdi-glass-mug-variant",
          text: "Ulster Bierproeffestival",
          route: "/ubpf",
        },
        {
          icon: "mdi-comment-processing",
          text: "Contact",
          route: "/contact",
        },
      ],
    };
  },

  methods: {},
  created() {
    this.$store.dispatch("loadIntros");
    this.$store.dispatch("getNewsItems");
    this.$store.dispatch("getNewsImages");
    this.$store.dispatch("loadContentHome");
  },
};
</script>

<style>
.ahcGreen {
  color: #305645;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
</style>