<template>
  <v-footer class="d-flex justify-center" padless color="#cc212f">
    <span class="white--text px-3">
      &#169;{{ new Date().getFullYear() }} —
      <strong>AHC de Vossen</strong>
    </span>
  </v-footer>
</template>

<script>
export default {
  name: "Bottom"
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
</style>