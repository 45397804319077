<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Kindercarnaval</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ kidsIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutModules">
      <v-flex xs12 md7 name="flexModulesNews">
        <v-flex xs12 class="ma-2" name="flexNews">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Nieuws over het Kindercarnaval</b>
              </span>
            </v-system-bar>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel
                v-model="panel"
                v-for="(item, i) in newsItems"
                :key="i"
              >
                <v-expansion-panel-header class="ahcGreen">
                  <b>{{ item.title }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-flex class="d-flex justify-center" xs12 v-if="item.foto">
                      <img :src="newsImages[item.id]" />
                    </v-flex>
                    <v-chip
                      v-for="(tag, j) in item.tags"
                      :key="j"
                      class="ma-2"
                      color="#ebb63b"
                      router
                      :to="`/${tag}`"
                    >
                      <span class="white--text">{{ tag }}</span>
                    </v-chip>
                    <v-card-text>
                      <p class="ahcGreen" tyle="white-space: pre-wrap;">
                        {{ item.text }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-flex>
      </v-flex>

      <v-flex xs12 md5 name="flexModulesProgramDownloads">
        <v-flex xs12 class="ma-2" v-if="contentKids.docs">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Downloads</b>
              </span>
            </v-system-bar>
            <v-list v-if="contentKids.docs.length > 0" two-line>
              <v-list-item v-for="(doc, i) in contentKids.docs" :key="i">
                <v-list-item-icon
                  v-if="doc.doc_id"
                  @click="downloadFile(doc.title, doc.doc_id, doc.type)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Axios from "axios";
import firebase from "firebase";
export default {
  data() {
    return {
      panel: 0,
    };
  },
  computed: {
    kidsIntro() {
      return this.$store.state.intros.kids;
    },
    contentKids() {
      return this.$store.state.contentKids;
    },
    newsImages() {
      return this.$store.state.newsImages;
    },
    newsItems() {
      return this.$store.state.kidsNewsItems;
    },
  },
  methods: {
    downloadFile(title, id, file_type) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;

      let file = firebase.storage().ref(`publicDocs/kids/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
  },
  created() {
    this.$store.dispatch("loadContentKids");
  },
};
</script>

<style>
</style>