<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Webshop</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ webshopIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout class="ma-3" name="layoutWebshop">
      <v-flex row wrap xs12 class="ma-2">
        <v-flex
          class="ma-3"
          lg4
          md6
          xs12
          v-for="(prod, i) in products"
          :key="i"
        >
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>{{ prod.title }}</b>
              </span>
            </v-system-bar>
            <v-card-text>
              <v-flex class="d-flex justify-center" xs12 v-if="prod.foto">
                <img
                  contain
                  width="80%"
                  class="img-circle"
                  :src="productImages[prod.id]"
                />
              </v-flex>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  computed: {
    webshopIntro() {
      return this.$store.state.intros.webshop;
    },
    products() {
      return this.$store.state.products;
    },
    productImages() {
      return this.$store.state.productImages;
    },
  },
  created() {
    this.$store.dispatch("loadProducts");
    this.$store.dispatch("getProductImages");
  },
};
</script>

<style>
</style>