<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Carnavalspagadder</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ pagadderIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutModules">
      <v-flex xs12 name="flexModulesNameImage">
        <v-flex class="d-flex justify-center ma-5" xs12 v-if="image">
          <v-card width="300" class="d-flex justify-center">
            <v-img :src="image" width="80%" contain></v-img>
          </v-card>
        </v-flex>
        <v-flex xs12 class="ma-2" name="flexName">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b"> </v-system-bar>
            <v-form class="ma-5">
              <div class="text-center mb-2">
                <span class="center green--text" v-if="feedback">{{
                  feedback
                }}</span>
              </div>
              <v-text-field
                v-model="pagadderSuggestion.suggestion"
                label="Welke naam moet de pagadder krijgen?"
              >
              </v-text-field>
              <v-text-field
                v-model="pagadderSuggestion.name"
                label="Wat is je naam? (optioneel)"
              >
              </v-text-field>
              <v-text-field
                v-model="pagadderSuggestion.email"
                label="Wat is je e-mail? (optioneel)"
              >
              </v-text-field>
              <v-btn
                class="ma-3"
                v-if="pagadderSuggestion.suggestion"
                @click="sendSuggestion()"
                >Aanmelding versturen</v-btn
              >
            </v-form>
          </v-card>
        </v-flex>
        <v-flex xs12 class="ma-2" name="flexNews">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Nieuws over de Carnavalspagadder</b>
              </span>
            </v-system-bar>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel
                v-model="panel"
                v-for="(item, i) in newsItems"
                :key="i"
              >
                <v-expansion-panel-header class="ahcGreen">
                  <b>{{ item.title }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-flex class="d-flex justify-center" xs12 v-if="item.foto">
                      <img :src="newsImages[item.id]" />
                    </v-flex>
                    <v-chip
                      v-for="(tag, j) in item.tags"
                      :key="j"
                      class="ma-2"
                      color="#ebb63b"
                      router
                      :to="`/${tag}`"
                    >
                      <span class="white--text">{{ tag }}</span>
                    </v-chip>
                    <v-card-text>
                      <p class="ahcGreen" tyle="white-space: pre-wrap;">
                        {{ item.text }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      panel: 0,
      pagadderSuggestion: {},
      feedback: null,
      image: null,
    };
  },
  computed: {
    newsItems() {
      return this.$store.state.pagadderNewsItems;
    },
    pagadderIntro() {
      return this.$store.state.intros.pagadder;
    },
    newsImages() {
      return this.$store.state.newsImages;
    },
  },
  methods: {
    sendSuggestion() {
      this.feedback = null;
      this.$store.dispatch("pagadderSuggestion", {
        suggestion: this.pagadderSuggestion,
      });
      this.pagadderSuggestion = {};
      this.feedback = "Bedankt voor je suggestie, we nemen hem mee!";
    },
  },
  created() {
    let storageRef = firebase.storage().ref(`images/pagadder/pagadder.png`);
    storageRef.getDownloadURL().then((url) => {
      this.image = url;
    });
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
</style>