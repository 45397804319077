<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <span class="ahcRed">Home</span>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 name="flexIntro">
        <v-flex xs12 class="ma-2" name="flexIntro">
          <v-alert outlined color="#ebb63b">
            <v-card flat pa-2>
              <p class="ahcGreen" style="white-space: pre-wrap">
                {{ homeIntro }}
              </p>
            </v-card>
          </v-alert>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutModulesHome">
      <v-flex xs12 md7 name="flexNews">
        <v-flex xs12 class="ma-2">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Nieuws uit het Vossenrijk</b>
              </span>
            </v-system-bar>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel
                v-model="panel"
                v-for="(item, i) in newsItems"
                :key="i"
              >
                <v-expansion-panel-header class="ahcGreen">
                  <b>{{ item.title }}</b>

                  <small class="d-flex justify-end">{{
                    item.creationDate
                  }}</small>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-flex class="d-flex justify-center" xs12 v-if="item.foto">
                      <img
                        :src="newsImages[item.id]"
                        contain
                        width="80%"
                        class="img-circle"
                      />
                    </v-flex>
                    <v-chip
                      router
                      :to="`/${tag}`"
                      v-for="(tag, j) in item.tags"
                      :key="j"
                      class="ma-2"
                      color="#ebb63b"
                    >
                      <span class="white--text">{{ tag }}</span>
                    </v-chip>
                    <v-card-text>
                      <p class="ahcGreen" tyle="white-space: pre-wrap;">
                        {{ item.text }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex xs12 md5 name="flexModulesAgendaThemeWist">
        <v-flex xs12 class="ma-2" name="flexTheme">
          <v-card>
            <v-system-bar class="pa-4 d-flex justify-center" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>{{ themeTitle }}</b>
              </span>
            </v-system-bar>
            <v-card-text>
              <h1 class="ahcRed my-3 d-flex justify-center">
                {{ themeText }}
              </h1>

              <div class="d-flex justify-center">
                <img
                  contain
                  width="80%"
                  class="img-circle"
                  v-if="themeImage"
                  :src="themeImage.url"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 class="ma-2" name="flexPrinsTabs" v-if="contentHome.prog">
          <v-card v-if="contentHome.prog.length > 0">
            <v-system-bar flat color="#ebb63b"> </v-system-bar>
            <v-tabs v-model="tab" align-with-title color="#cc212f">
              <v-tabs-slider color="#cc212f"></v-tabs-slider>
              <v-tab v-for="(prog, i) in contentHome.prog" :key="i">
                {{ prog.name }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(prog, i) in contentHome.prog" :key="i">
                <v-card flat>
                  <v-row class="d-flex justify-space-around ma-3">
                    <img v-if="prog.image" :src="prog.foto" max-width="80%" />
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-flex>
        <v-flex xs12 class="ma-2">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Voor in de agenda</b>
              </span>
            </v-system-bar>
            <template class="ma-4" v-if="agenda && agenda.length > 0">
              <v-data-table
                :headers="calendarHeaders"
                :items="agenda"
                class="elevation-0"
                sortBy="date"
                hide-default-footer
                loading-text="Het jaarprogramma wordt ingeladen"
                disable-pagination
              >
              </v-data-table>
            </template>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      panel: 0,
      calendarHeaders: [
        {
          text: "Datum",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Activiteit",
          sortable: false,
          value: "name",
        },
      ],
      tab: null,
    };
  },
  computed: {
    newsItems() {
      return this.$store.state.newsItems;
    },
    homeIntro() {
      return this.$store.state.intros.home;
    },
    agenda() {
      return this.$store.state.intros.agenda;
    },
    newsImages() {
      return this.$store.state.newsImages;
    },
    themeTitle() {
      return this.$store.state.intros.themeTitle;
    },
    themeText() {
      return this.$store.state.intros.themeText;
    },
    themeImage() {
      return this.$store.state.intros.themeImage;
    },
    contentHome() {
      return this.$store.state.contentHome;
    },
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
.ahcYellow {
  color: #ebb63b;
}
.img-circle {
  border-radius: 7.5%;
}
</style>